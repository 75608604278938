import React from 'react';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import styles from './styles.module.sass';

export default ({
  count,
  current,
  onNext,
  onPrev
}) => (
  <Grid item container
    xs={12} xl={12} justifyContent='flex-start' className={styles.topContainer}
    alignContent='center'
    alignItems='center'>
    {current - 2 >= 0 ? (
      <Button onClick={onPrev}
        className={styles.button}>
        <svg
          viewBox="0 0 48 48"
          width='100%'
          height='100%'
          transform="rotate(90, 0, 0)"
          fill='currentColor'>
          <path d="M16 20l8 8 8-8H16z"/>
        </svg>
      </Button>
    ) : null}
    <div className={styles.filterContainer}>
      <Typography variant='h5' weight='bold' color='dark' align='left'>
        {`${i18n('Posts')} ${current + 1} - ${current + 2 < count ? current + 2 : count} ${i18n('of')} ${count}`}
      </Typography>
    </div>
    {current + 2 < count ? (
      <Button onClick={onNext}
        className={styles.button}>
        <svg
          viewBox="0 0 48 48"
          width='100%'
          height='100%'
          transform="rotate(270, 0, 0)"
          fill='currentColor'>
          <path d="M16 20l8 8 8-8H16z"/>
        </svg>
      </Button>
    ) : null}
  </Grid>
)
