import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { Grid } from '@material-ui/core';
import WhoAreWe from './WhoAreWe';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        header: imageSharp (fluid: {
          originalName: { eq: "Blog-Header.jpg" }
        }) {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ header }) => (
      <Grid container className={styles.container}>
        <Grid item xs={12} md={12} lg={7} xl={8} container justifyContent='center' alignContent='center' alignItems='center'
          className={styles.headerImgContainer}>
          <Img objectFit='contain'
            className={styles.imageContainer}
            fluid={header.fluid}
            alt={'OBI+ blog'}/>
        </Grid>
        <WhoAreWe/>
      </Grid>
    )}
  />
)