import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Content from "../views/Blog";
import seo from './blog/metadata.json';

export default ({location}) => {
  const [ current, setCurrent ] = React.useState(0);

  return (
    <StaticQuery
      query={graphql`
        query {
          blogs: allBlogpostsJson {
            nodes {
              metadata {
                description
                keyword
                title
                url
              }
              title
              createdAt
              indexable
              localization
              banner {
                url
              }
              thumbnail {
                url
              }
              _id
            }
          }
        }
      `} 
      render={({ blogs }) => {
        const sorted = blogs.nodes
        .sort(({ createdAt }, { createdAt: createdAt_ }) => createdAt < createdAt_ ? 1 : -1);
        const count = sorted.length;
        const start = current - 2 > 0 ? current - 2 : 0; 
        const end = current + 2 < count ? current + 2 : count - 1;

        return (
          <Content seo={seo} location={location}
            onNext={() => setCurrent(end)}
            onPrev={() => setCurrent(start)}
            count={count}
            current={current}
            blogs={sorted.slice(current, current + 2 < count ? current + 2 : count)}/>
        );
      }}
    />
  )
};