import React from "react";
import Layout from "../../components/Layout"
import SEO from "../../components/seo";
import CallToAction from "../CallToAction";
import Top from './Top';
import Paged from './Paged';
import sitemap from '../../data/sitemap';

export default ({
  seo,
  blogs,
  count,
  current,
  onNext,
  onPrev,
  location,
}) => (
  <Layout page={sitemap.blog}
    overlay
    url='_url:blog'>
    <SEO {...seo} location={location}/>
    <Top/>
    <Paged blogs={blogs}
      onNext={onNext}
      onPrev={onPrev}
      current={current}
      count={count}/>
    <CallToAction/>
  </Layout>
);