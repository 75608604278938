import React from 'react';
import 'whatwg-fetch';
import { Grid } from '@material-ui/core';
import Counter from './Counter';
import Entry from './Entry';
import styles from './styles.module.sass';

export default ({
  blogs,
  count,
  current,
  onPrev,
  onNext,
}) => (
  <Grid container className={styles.container}>
    <Counter count={count}
      onPrev={onPrev}
      onNext={onNext}
      current={current}/>
    {blogs.map((b, i) => <Entry key={i} {...b}/>)}
  </Grid>
);
